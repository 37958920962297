import Home from './Pages/HomePage/Home'
import Admin from './Pages/AdminPage/Admin';

import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

// import { ColorModeProvider } from '@chakra-ui/react';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="admin" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
