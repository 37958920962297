import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Circle,
  Center,
  Text,
  Input,
  Box,
  Grid,
  GridItem,
  InputLeftElement,
  InputGroup,
  Progress,
  Select,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { EditIcon, LockIcon, ExternalLinkIcon, Icon } from "@chakra-ui/icons";
import axios from "axios";
import db from "../../firebase";
import { ref, set } from "firebase/database";

function CreateModals({ props }) {
  let x;
  const [progress, setProgress] = useState({ started: false, pc: 0 });
  const [msg, setMsg] = useState(null);
  const [title, setTitle] = useState("");
  const [ID, setID] = useState("");
  const [file, setFile] = useState(null);
  const [icon, setIcon] = useState(null);
  const [price, setPrice] = useState();
  const [many, setMany] = useState(1);
  const [account, setAccount] = useState(() => {
    return Array.from({ length: many }, (_, index) => ({ mail: '', pass: '' }));
  });
  
  useEffect(() => {
    setAccount(Array.from({ length: many }, (_, index) => ({ mail: '', pass: '' })));
  }, [many]);

  const DollarIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="9"
      viewBox="0 0 288 512"
    >
      <path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7 .5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z" />
    </svg>
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = async (e) => {
    e.preventDefault();
    x = new Date().getTime();
    if (!file) {
      setMsg("No file selected");
    }

    const fd = new FormData();
    fd.append("file", file);
    setMsg("Uploading...");
    setProgress((prevState) => {
      return { ...prevState, started: true };
    });
    await axios
      .post("http://httpbin.org/post", fd, {
        onUploadProgress: (progressEvent) => {
          setProgress((prevState) => {
            return { ...prevState, pc: progressEvent.progress * 100 };
          });
        },
        headers: {
          "Custom-Header": "value",
        },
      })
      .then((res) => {
        setIcon(res.data.files.file);
        setMsg("Upload succesfuly");
        setProgress((prevState) => {
          return { ...prevState, started: false };
        });
      })
      .catch((err) => {
        setMsg("Upload failed");
        setProgress((prevState) => {
          return { ...prevState, started: false };
        });
        console.log(err);
      });

    const WriteDB = (e) => {
      e.preventDefault();
      const uuid = "web/" + ID;
      set(ref(db, `/${uuid}`), {
        icon,
        title,
        account,
        price,
      });
    };
    console.log("İcon " + icon);
    console.log("File " + file);
    WriteDB(e);
    setTimeout(() => {
      onClose();
    }, 1000);
  };
  const updateMail = (index, newValue, prop) => {
    const updatedAccount = [...account];
    if (prop === "mail") {
      updatedAccount[index] = { ...updatedAccount[index], mail: newValue };
    }
    if (prop === "pass") {
      updatedAccount[index] = { ...updatedAccount[index], pass: newValue };
    }
    setAccount(updatedAccount);
  };
  const handleSubmitTest =(e)=>{
    e.preventDefault()
    console.log('İcon:\n',icon)
    console.log('Title:\n',title)
    console.log('Price:\n',price)
    console.log('ID:\n',ID)
    console.log('Many:\n',many)
    console.log('Accounts:\n',account)
  }



  return (
    <>
      <Circle
        position={"absolute"}
        bottom={0}
        right={0}
        size={"60px"}
        bgColor={"green.500"}
        color={"white"}
        onClick={onOpen}
      >
        <Center>
          <AddIcon boxSize={"30px"} />
        </Center>
      </Circle>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Yeni Ürün Girişi</ModalHeader>
          <ModalCloseButton />
            <form onSubmit={handleSubmit}>
          <ModalBody>
              <Grid gap={4}>
                <GridItem colStart={1} colEnd={8} rowSpan={1}>
                  <Box>
                    <Text justifyContent={"flex-start"}>İcon Yükleyin</Text>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                        >
                          <path
                            fill="#f57c00"
                            d="M40,41H8c-2.2,0-4-1.8-4-4V11c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v26C44,39.2,42.2,41,40,41z"
                          ></path>
                          <path
                            fill="#fff9c4"
                            d="M35,13c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S36.7,13,35,13z"
                          ></path>
                          <path fill="#942a09" d="M20,16L9,32h22L20,16z"></path>
                          <path
                            fill="#bf360c"
                            d="M31,22l-8,10h16L31,22z"
                          ></path>
                        </svg>
                      </InputLeftElement>
                      <Input
                        paddingTop={"0.5vh"}
                        onChange={(e) => setFile(e.target.files[0])}
                        type="file"
                      />
                    </InputGroup>
                  </Box>
                </GridItem>
                <GridItem colStart={1} colEnd={8} rowSpan={1}>
                  <Box>
                    <Text>Ürün Adı</Text>
                    <InputGroup>
                      <InputLeftElement>
                        <ExternalLinkIcon />
                      </InputLeftElement>
                      <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={"Ürün Adını Giriniz"}
                      />
                    </InputGroup>
                  </Box>
                </GridItem>
                <GridItem colStart={1} colEnd={3}>
                  <Box>
                    <Text>Fiyat</Text>
                    <InputGroup>
                      <InputLeftElement>
                        <Icon as={DollarIcon} boxSize={8} color="green.500" />
                      </InputLeftElement>
                      <Input
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(parseFloat(e.target.value))}
                        placeholder="Ürün Fiyatını Giriniz"
                      />
                    </InputGroup>
                  </Box>
                </GridItem>
                <GridItem colStart={3} colEnd={8}>
                  <Box>
                    <Text>ID</Text>
                    <InputGroup>
                      <InputLeftElement>
                        <LockIcon />
                      </InputLeftElement>
                      <Input
                        value={ID}
                        onChange={(e) => setID(e.target.value)}
                        placeholder="Öğenin IDsini Giriniz"
                      />
                    </InputGroup>
                  </Box>
                </GridItem>
                <GridItem colStart={1} colEnd={8}>
                  <Box>
                    <Text>Eklenecek Hesap Sayısı</Text>

                    <Select
                      defaultValue={1}
                      onChange={(e) => setMany(e.target.value)}
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                    </Select>
                  </Box>
                </GridItem>

                
                { isOpen ?
                
                Array.from({length:parseInt(many)}).map((item, key) => {

                      
                      
                  
                  return(
                    <React.Fragment key={key}>
                    <GridItem colStart={1} colEnd={6}>
                      <Box>
                        <Text>Mail & Kullanıcı Adı</Text>
                        <InputGroup>
                          <InputLeftElement>
                            <EditIcon />
                          </InputLeftElement>
                          <Input
                            value={account[key] ? account[key].mail : ''}
                            onChange={(e) =>
                              updateMail(key, e.target.value, "mail")
                            }
                            placeholder="Mail & kullanıcı adını giriniz"
                          />
                          {}
                        </InputGroup>
                      </Box>
                    </GridItem>
                    <GridItem colStart={6} colEnd={8}>
                      <Box>
                        <Text>Şifre</Text>
                        <InputGroup>
                          <InputLeftElement>
                            <LockIcon />
                          </InputLeftElement>
                          <Input
                            value={account[key] ? account[key].pass : ''}
                            onChange={(e) =>
                              updateMail(key, e.target.value, "pass")
                            }
                            placeholder="Hesabın şifresini giriniz"
                          />
                        </InputGroup>
                      </Box>
                    </GridItem>
                  </React.Fragment>
                  )
                }):""}

                <GridItem colStart={1} colEnd={8} rowSpan={1}>
                  <Box>
                    <Button
                      type="submit"
                      borderWidth={2}
                      textColor={"white"}
                      _hover={{
                        bgGradient:
                          "linear-gradient(85deg, rgba(255,94,255,0.7) 0%, rgba(63,94,251,0.7035189075630253) 100%)",
                      }}
                      bgGradient={
                        "linear-gradient(85deg, rgba(255,94,255,1) 0%, rgba(63,94,251,1) 100%)"
                      }
                      w={"100%"}
                    >
                      Submit
                    </Button>
                  </Box>
                </GridItem>
                <GridItem colStart={1} colEnd={8} rowSpan={1}>
                  {progress.started && (
                    <Box>
                      <Progress
                        borderRadius={"0.5em"}
                        hasStripe
                        value={progress.pc}
                      />
                    </Box>
                  )}

                  {msg && <p>{msg}</p>}
                </GridItem>
              </Grid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
                      type="submit"
                      borderWidth={2}
                      textColor={"white"}
                      _hover={{
                        bgGradient:
                        "linear-gradient(85deg, rgba(255,94,255,0.7) 0%, rgba(63,94,251,0.7035189075630253) 100%)",
                      }}
                      bgGradient={
                        "linear-gradient(85deg, rgba(255,94,255,1) 0%, rgba(63,94,251,1) 100%)"
                      }
                      w={"100%"}
                      >
                      Submit
                    </Button>
          </ModalFooter>
                      </form>
        </ModalContent>
      </Modal>
    </>
  );
}
export default CreateModals;
