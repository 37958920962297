import React from "react";
import { Box,Image,} from "@chakra-ui/react";

import Modals from "../Modals/Modals";

function GameCard({props,onClick}){
    const formattedPrice= "₺ "+props.price
    const formattedAccount= props.accounts
    return(
        <Box onClick={onClick} maxW="240px" borderWidth="1px" borderRadius="lg" overflow="hidden" m={3} >
        <Image h={'240px'} w={"240px"} src={props.icon}  />

        <Box p="6">
          <Box display="flex" alignItems="baseline">
            
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {Object(props.accounts).length+" Hesap"} &bull; {'--kişi'} &bull; {formattedPrice +"\n"}
            </Box>
          </Box>

          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            noOfLines={1}
          >
            {props.title}
          </Box>


          <Box>
            
            
          </Box>
        </Box>



      </Box>
    )
}

export default GameCard