import React from "react";
import {
  HStack,
  VStack,
  Flex,
  Text,
  Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure
  } from '@chakra-ui/react'

  import GameCard from "../GameCard/GameCard";
  

function Modals({props}) {

    const { isOpen, onOpen, onClose } = useDisclosure()
  console.log(props);
    return(
        <>
      <GameCard props={props} onClick={onOpen}/>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

           <HStack>
              <Image w={'150px'}h={'150px'} src={props.icon} />

              <VStack align={'stretch'}>
                 
                <Text>Hesap sayısı: 100</Text>
                
                <Text>mail: mail@gmail.com</Text>
                <Text>mail2: burak@gmail.com</Text>
              </VStack>
           </HStack>
             
            
            
            
            
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
    )
}
export default Modals
