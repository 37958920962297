import React, { useEffect, useState } from "react";
import {
  Text,
  Input,
  Flex,
  Box,
  Grid,
  GridItem,
  InputLeftElement,
  InputGroup,
  Button,
  Progress,
  Select,
} from "@chakra-ui/react";
import { EditIcon, LockIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import axios from "axios";
import db from "../../firebase";
import { ref, set } from "firebase/database";

function Admin() {
  let x, y;

  const [progress, setProgress] = useState({ started: false, pc: 0 });
  const [msg, setMsg] = useState(null);
  const [title, setTitle] = useState("");
  const [ID, setID] = useState("");
  const [file, setFile] = useState(null);
  const [icon, setIcon] = useState(null);
  const [account, setAccount] = useState([{mail:'mail.com',pass:'pass123  '},{mail:'gmail.com',pass:'asdsa'}]);
  const [price, setPrice] = useState();
  const [many ,setMany]=useState(1);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    x = new Date().getTime();
    if (!file) {
      setMsg("No file selected");
    }

    const fd = new FormData();
    fd.append("file", file);
    setMsg("Uploading...");
    setProgress((prevState) => {
      return { ...prevState, started: true };
    });
    await axios
      .post("http://httpbin.org/post", fd, {
        onUploadProgress: (progressEvent) => {
          setProgress((prevState) => {
            return { ...prevState, pc: progressEvent.progress * 100 };
          });
        },
        headers: {
          "Custom-Header": "value",
        },
      })
      .then((res) => {
        setIcon(res.data.files.file);
        setMsg("Upload succesfuly");
        setProgress((prevState) => {
          return { ...prevState, started: false };
        });
      })
      .catch((err) => {
        setMsg("Upload failed");
        setProgress((prevState) => {
          return { ...prevState, started: false };
        });
        console.log(err);
      });

    const WriteDB = (e) => {
      e.preventDefault();
      const uuid = "steam/";
      set(ref(db, `/${uuid}`), {});
    };
  };

    const updateMail = (index, newValue,prop) => {
      const updatedAccount = [...account];
      if(prop==='mail')
      {updatedAccount[index] = { ...updatedAccount[index], mail: newValue };}
      if(prop==='pass'){
        updatedAccount[index] = { ...updatedAccount[index], pass: newValue };
      }
      setAccount(updatedAccount);
    };
  


  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      h={"100vh"}
      bg={"rgb(1, 22, 39)"}
      textColor={"white"}
    >
      <form onSubmit={handleSubmit}>
        <Grid gap={4}>
          <GridItem colStart={1} colEnd={16} rowSpan={1}>
            <Box>
              <Text justifyContent={"flex-start"}>İcon Yükleyin</Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#f57c00"
                      d="M40,41H8c-2.2,0-4-1.8-4-4V11c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v26C44,39.2,42.2,41,40,41z"
                    ></path>
                    <path
                      fill="#fff9c4"
                      d="M35,13c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S36.7,13,35,13z"
                    ></path>
                    <path fill="#942a09" d="M20,16L9,32h22L20,16z"></path>
                    <path fill="#bf360c" d="M31,22l-8,10h16L31,22z"></path>
                  </svg>
                </InputLeftElement>
                <Input
                  paddingTop={"0.5vh"}
                  onChange={(e) => setFile(e.target.files[0])}
                  type="file"
                />
              </InputGroup>
            </Box>
          </GridItem>
          <GridItem colStart={1} colEnd={16} rowSpan={1}>
            <Box>
              <Text>Ürün Adı</Text>
              <InputGroup>
                <InputLeftElement>
                  <ExternalLinkIcon color={"white"} />
                </InputLeftElement>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder={"Ürün Adını Giriniz"}
                />
              </InputGroup>
            </Box>
          </GridItem>
          <GridItem colStart={1} colEnd={8}>
            <Box>
              <Text>Fiyat</Text>
              <InputGroup>
                <InputLeftElement>
                  <EditIcon color={"white"} />
                </InputLeftElement>
                <Input
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="Ürün Fiyatını Giriniz"
                />
              </InputGroup>
            </Box>
          </GridItem>
          <GridItem colStart={8} colEnd={16}>
            <Box>
              <Text>ID</Text>
              <InputGroup>
                <InputLeftElement>
                  <LockIcon color={"white"} />
                </InputLeftElement>
                <Input
                  value={ID}
                  onChange={(e) => setID(e.target.value)}
                  placeholder="Öğenin IDsini Giriniz"
                />
              </InputGroup>
            </Box>
          </GridItem>
          <GridItem colStart={1} colEnd={16}>
            <Box>
              <Text>Eklenecek Hesap Sayısı</Text>
              
                <Select defaultValue={1} onChange={(e) => setMany(e.target.value)} placeholder="Hesap Miktarı">
                    
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </Select>
              
            </Box>
          </GridItem>
          
            
            {
            
               [... Array(parseInt(many))].map((item,key)=>(
                
                    <>
                    <GridItem colStart={1} colEnd={8}>
            <Box>
              <Text>Mail Adresi & Kullanıcı Adı</Text>
              <InputGroup>
                <InputLeftElement>
                  <EditIcon color={"white"} />
                </InputLeftElement>
                <Input
                  value={account[key].mail}
                  onChange={(e) => updateMail(key,e.target.value,'mail')}
                  placeholder="Ürün Fiyatını Giriniz"
                />
                {}
              </InputGroup>
            </Box>
          </GridItem>
          <GridItem colStart={8} colEnd={16}>
            <Box>
              <Text>Şifre</Text>
              <InputGroup>
                <InputLeftElement>
                  <LockIcon color={"white"} />
                </InputLeftElement>
                <Input
                  value={account[key].pass}
                  onChange={(e)=> updateMail(key,e.target.value,'pass')}
                  placeholder="Öğenin IDsini Giriniz"
                />
              </InputGroup>
            </Box>
          </GridItem></> 
                ))

            }
          
          <GridItem colStart={1} colEnd={16} rowSpan={1}>
            <Box>
              <Button
                type="submit"
                borderWidth={2}
                textColor={"white"}
                _hover={{
                  bgGradient:
                    "linear-gradient(85deg, rgba(255,94,255,0.7) 0%, rgba(63,94,251,0.7035189075630253) 100%)",
                }}
                bgGradient={
                  "linear-gradient(85deg, rgba(255,94,255,1) 0%, rgba(63,94,251,1) 100%)"
                }
                w={"100%"}
              >
                Submit
              </Button>
            </Box>
          </GridItem>
          {progress.started && (
            <GridItem colStart={1} colEnd={16} rowSpan={1}>
              <Box>
                <Progress
                  borderRadius={"0.5em"}
                  hasStripe
                  value={progress.pc}
                />
              </Box>
            </GridItem>
          )}
          {msg && <p>{msg}</p>}
        </Grid>
      </form>
    </Flex>
  );
}

export default Admin;

