import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  Image,
  Button,
  HStack,
  Badge,
  Flex,
  
} from "@chakra-ui/react";
import db from "../../firebase";
import { onValue, ref, remove, set, update } from "firebase/database";

import GameCard from "../../components/GameCard/GameCard";
import Modals from "../../components/Modals/Modals";
import CreateModals from "../../components/Modals/CreateModals";
function Home() {
  const [list, setList] = useState([]);

  useEffect(() => {
    onValue(ref(db, "/web"), (snapshot) => {
      setList([]);
      const data = snapshot.val();
      if (data !== null) {
        Object.values(data).map((item) => {
          setList((prevState) => [...prevState, item]);
        });
      }
    });
  }, []);
  //Add icon onclik eventi ekle modalı açılacak

  return (
    <>
      <Flex flexDirection={"row"}>
        {list.map((item, key) => (
          <Modals key={key} props={item} />
        ))}
      </Flex>
      <CreateModals />
    </>
  );
}
export default Home;

/**
 *
 *
 */
